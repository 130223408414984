import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import HistoryLayout from '../components/HistoryLayout'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Seo from '../components/Seo'
import CookiePopUp from '../components/CookiePopUp'

/**
* History page 
* 
* @param {object} data
* @param {object} pageContext
*
* @returns {JSX Element}
*/
export default function historyTemplate({ data }) {

  const { wpPage: { translations, language, history_template, title }, allWpMenu, allWp: { nodes: [optionsPage] } } = data

  return (
    <>
      <Seo title={title} image={history_template?.historyRepeater[0]?.image?.file?.historyRepeaterImage?.fluid?.src} lang={language.locale} />
      <Header menuData={allWpMenu} language={language} translations={translations} />
      <HistoryLayout historyArray={history_template} />
      <CookiePopUp language={language} />
      <Footer menuData={allWpMenu} language={language} footerOptionsPage={optionsPage} />
    </>
  )
}

historyTemplate.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
}
export const infoRepeaterQuery = graphql`
  query historyPageByID($id: String) {
    wpPage(id: {eq: $id}) {
      id
      translations {
        uri
      }
      language {
        locale
      }
      title
      history_template {
        historyRepeater {
          description
          hasPortrait
          image {
            file: localFile {
              historyRepeaterImage: childImageSharp {
                fluid(maxWidth: 1200) {
                  aspectRatio
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          title
          year
        }
      }
    }
    allWpMenu {
      ...getMenus
    }
    allWp {
      nodes {
        ...getOptionsPageFooterData
      }
    }
  }
`